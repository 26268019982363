import React, { useEffect } from "react";
import { motion } from "framer-motion";
// import { theme } from 'styled-tools';
import { HeadingTextWrapper } from "../../common/Headings";
// import CasinoSlider from './casino-slider';
// import RecommendedGames from './recommended-games';
import CasinoTabGames from "./casino-tab-games";
// import { color } from "../../../sass-files/variable";
// import casinoBg from '../../../images/iexch_casino_bgone.jpg';
// import Intl from "../../common/Intl";
import styled, { withTheme } from "styled-components";
import { withAppContext } from "src/store/initAppContext";
import config from "src/config/config";
import { Link, useParams } from "react-router-dom";
import SEOContents from "src/components/SEOContents";
import { Helmet } from "react-helmet";
import { CRMPageVisit } from "src/util/crmUtil";
// import CasinoSlider from "./casino-slider";
// import Slider from "react-slick";
// const {titleHeadColor} = color
// const andarBahar = `${'/static/media/andarBaharslide.jpg'}`
// const AviatorSlide = `${'/static/media/AviatorSlide.jpg'}`
// const CrazyTimeSlide = `${'/static/media/CrazyTimeSlide.jpg'}`
// const DreamCatcherSlide = `${'/static/media/DreamCatcherSlide.jpg'}`
// const goalSlide = `${'/static/media/goalSlide.jpg'}`
// const Hiloslide = `${'/static/media/Hiloslide.jpg'}`
// const LightningRouletteSlide = `${'/static/media/LightningRouletteSlide.jpg'}`
// const TeenpattiSlide = `${'/static/media/TeenpattiSlide.jpg'}`
// const MonopolySlide = `${'/static/media/MonopolySlide.jpg'}`
// const VegasMagic=`${'/static/media/VegasMagicSlide.jpg'}`


// const imageGroup: any = {
//   web: [
//     {
//       img: 'slider_1', name: 'slider_image_1', id: 1, src:AviatorSlide, alt_name: 'aviator', pathName: '/online-casino-games/aviator'
//     },
//     {
//       img: 'slider_2', name: 'slider_image_2', id: 2, src: andarBahar, alt_name: 'Banner_image_11', pathName: '/online-casino-games/andar bahar'
//     },
//     {
//       img: 'slider_6', name: 'slider_image_6', id: 6, src:  CrazyTimeSlide, alt_name: 'smartsoft', pathName: '/online-casino-games/EVOLUTIONBLACKJACK'
//     },
//     {
//       img: 'slider_2', name: 'slider_image_2', id: 2, src:DreamCatcherSlide, alt_name: 'lodo', pathName:'/online-casino-games/EVOLUTIONBLACKJACK'
//     }, 
//     {
//       img: 'slider_3', name: 'slider_image_3', id: 3, src:  goalSlide, alt_name: 'Banner_image_1', pathName: '/online-casino-games/goal'
//     },
//     {
//       img: 'slider_3', name: 'slider_image_3', id: 3, src:  Hiloslide, alt_name: 'Banner_image_1', pathName: '/online-casino-games/hilo'
//     },
//     {
//       img: 'slider_3', name: 'slider_image_3', id: 3, src:  LightningRouletteSlide, alt_name: 'Banner_image_1', pathName: '/online-casino-games/Lightning'
//     },
//     {
//       img: 'slider_3', name: 'slider_image_3', id: 3, src:  TeenpattiSlide, alt_name: 'Banner_image_1', pathName: '/online-casino-games/teenpatti'
//     },
//     {
//       img: 'slider_3', name: 'slider_image_3', id: 3, src:  MonopolySlide, alt_name: 'Banner_image_1', pathName: '/online-casino-games'
//     },
//     {
//       img: 'slider_3', name: 'slider_image_3', id: 3, src:  VegasMagic, alt_name: 'Banner_image_1', pathName: '/online-casino-games/populargames/vegas'
//     }


//   ]
// }

// const sliderSettings = {
//   dots: true,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   autoplay: true,
//   arrows: false,
//   pauseOnHover:true
// };
const labelTxt = (id) => {
  let obj = {
    'spribe': 'Spribe Games',
    'supernowa': 'Supernowa Games',
    'superspade': 'Superspade Games',
    'tvbet': 'TVbet Games',
    'betgames': 'BetGames',
    'bingo': 'Bingo',
    'ezugi': 'Ezugi Games',
    'ag': 'AG Games',
    'evo': 'EVO Games',
    '7MOJOS': '7Mojos Games',
    'macaw': 'Macaw Games',
    'kingmaker': 'Kingmaker Games',
    'sexygaming': 'Sexygaming Games',
    'lightningroulette': 'Play Lightening Roulette',
    'crazytime': 'Crazy Time',
    'monopolylive': 'Play Monopoly Live',
    'funkytime': 'FunkyTime',
    'lightningdice': 'Play LighteningDice',
    'dragontiger': 'Play DragonTiger🐉',
    'blackjack': 'Play Blackjack',
    'roulette': 'Play Roulette',
    'baccarat': 'Play Baccarat',
    'dragon-tiger': 'Play Dragon Tiger',
    'crazy-time': 'Crazy Time',
    'monopoly-live': 'Live Monopoly',
    'funky-time': 'Play funky Time'


  }
  return obj[id] || '';
}
const structuredData = (domain, id) => {
  const data = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement":
      [
        {
          "@type": "ListItem",
          "item": `${domain}`,
          "name": "Homepage",
          "position": 1
        },
        {
          "@type": "ListItem",
          "item": `${domain}/online-casino-games/`,
          "name": "Play Online Casino Games",
          "position": 2
        },
        {
          "@type": "ListItem",
          "item": `${domain}/online-casino-games/${id}`,
          "name": `${labelTxt(id)}`,
          "position": 3
        }],
    "name": "Breadcrumbs"
  }

  return JSON.stringify(data)
}

const structuredData2 = (domain) => {
  const data = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement":
      [
        {
          "@type": "ListItem",
          "item": `${domain}`,
          "name": "Homepage",
          "position": 1
        },
        {
          "@type": "ListItem",
          "item": `${domain}/online-casino-games/`,
          "name": "Play Online Casino Games",
          "position": 2
        }
      ],
    "name": "Breadcrumbs"
  }

  return JSON.stringify(data)
}




function CasinoPage(props) {
  const { id } = useParams();
  const { app: { brandId } } = props;

  let domainURL = 'https://www.yolo247.site';

  if (brandId === 32) {
    domainURL = 'https://www.yolo247.club';
  }
  else if (brandId === 33) {
    domainURL = 'https://www.baaziadda.com';
  }
  else if (brandId === 34) {
    domainURL = 'https://www.betdaily.club';
  }

  useEffect(() => {
    //for CRM
    CRMPageVisit();
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {!id &&
        <>
          <SEOContents pageName={"onlineCasinoGames"} />
          <Helmet>
            <script type="application/ld+json">
              {
                structuredData2(domainURL)
              }
            </script>
          </Helmet>
        </>
      }

      {id && id === 'blackjack' &&
        <>
          <SEOContents pageName={"blackjack"} />
        </>
      }


      {id && id === 'roulette' &&
        <>
          <SEOContents pageName={"roulette"} />
        </>
      }

      {id && id === 'dragon-tiger' &&
        <>
          <SEOContents pageName={"dragonTiger"} />
        </>
      }

      {id && id === 'baccarat' &&
        <>
          <SEOContents pageName={"baccarat"} />
        </>
      }

      {id && id === 'aviator' &&
        <>
          <SEOContents pageName={"aviator"} />
        </>
      }

      {id && id === 'macludo' &&
        <>
          <SEOContents pageName={"macludo"} />
        </>
      }

      {id && id === 'SPRIBE' &&
        <>
          <SEOContents pageName={"SPRIBE"} />
        </>
      }

      {id && id === '7MOJOS' &&
        <>
          <SEOContents pageName={"7MOJOS"} />
        </>
      }

      {id && id === 'supernowa' &&
        <>
          <SEOContents pageName={"supernowa"} />
        </>
      }

      {id && id === 'superspade' &&
        <>
          <SEOContents pageName={"superspade"} />
        </>
      }


      {id && id === 'tvbet' &&
        <>
          <SEOContents pageName={"tvbet"} />
        </>
      }

      {id && id === 'betgames' &&
        <>
          <SEOContents pageName={"betgames"} />

        </>
      }

      {id && id === 'sexygaming' &&
        <>
          <SEOContents pageName={"sexygaming"} />
        </>
      }

      {id && id === 'bingo' &&
        <>
          <SEOContents pageName={"bingo"} />

        </>
      }


      {id && id === 'ag' &&
        <>
          <SEOContents pageName={"ag"} />

        </>
      }

      {id && id === 'Funkytime' &&
        <>
          <SEOContents pageName={"Funkytime"} />
        </>
      }
      {id && id === 'macludo' &&
        <>
          <SEOContents pageName={"macludo"} />
        </>
      }
      {id && id === 'evo' &&
        <>
          <SEOContents pageName={"evo"} />

        </>
      }

      {id && id === 'nlc' &&
        <>
          <SEOContents pageName={"nlc"} />
        </>
      }
      {id && id === 'netent' &&
        <>
          <SEOContents pageName={"netent"} />
        </>
      }
      {id && id === 'Funky Time' &&
        <>
          <SEOContents pageName={"Funky Time"} />
        </>
      }
      {id && id === 'first person blackjack' &&
        <>
          <SEOContents pageName={"First-Person-Blackjack"} />
        </>
      }
      {id && id === 'teenpatti 20 20' &&
        <>
          <SEOContents pageName={"teenpatti2020-2020"} />
        </>
      }
      {id && id === 'funkytime' &&
        <Helmet>
          <link rel="canonical" href={`${domainURL}/online-casino-games/funky-time`} />
          <link rel="canonical" href={`${domainURL}/online-casino-games/funky-time/`} />
        </Helmet>
      }

      {id &&
        <Helmet>
          <script type="application/ld+json">
            {
              structuredData(domainURL, id)
            }
          </script>
        </Helmet>
      }

      {id && id === 'crazy-time' && (
        <>
          <SEOContents pageName={"crazytime"} />
        </>
      )

      }



      {/* As per discusion with Vishnu slider is hidded */}
      {/* <SliderWrap>
        <Slider {...sliderSettings}>
          {
              imageGroup['web'].map((each, index) => {
                  return (
                      <div key={index}>
                          <Link className="banner_img" to={each.pathName}>
                              <img src={`${config.imageV3Host}${each.src}`} alt={each.alt_name} height="auto" width="100%" />
                          </Link>
                      </div>
                  )
              })
          }
        </Slider>
      </SliderWrap> */}

      <CasinoWrapper theme={props.app.theme} customStyle={props.theme.image}>
        {/* <TitleHead theme={props.app.theme} customStyle={props.theme.palette}>
          <Intl langKey="TITLE@CASINO" />
        </TitleHead> */}
        {/* <RecommendedGames/> */}
        {/* <CasinoSlider/> */}
        <CasinoTabGames />
      </CasinoWrapper>
    </motion.div>
  );
}

export default withTheme(withAppContext(CasinoPage));

const CasinoWrapper: any = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-image: url("${(props: any) => `${config.imageV3Host}/static/cashsite/${props.customStyle["homeExchangeBgImage"]}`}");
  background-position: 100%;
  background-size: cover;
`;

const TitleHead = styled(HeadingTextWrapper)`
  font-size: 28px;
  letter-spacing: 3px;
  text-transform: uppercase;
  white-space: nowrap;
  font-weight: bold;
  color: ${(props: any) => (props.theme === "Dark" ? "#FFF" : "#F4A322")};
  margin-left: 1em;
  padding-top: 10px;
  position: relative;
  margin-bottom: 10px;
  padding-bottom: 5px;

  :after {
    content: "";
    width: 500px;
    height: 2px;
    background: linear-gradient(90deg, #f7b349 0%, rgba(235, 160, 42, 0) 50%);
    position: absolute;
    bottom: 0;
    left: 0;
  }

  @media only screen and (max-width: 992px) {
    font-size: 30px;
  }
`;

const SliderWrap = styled.div`
  /* min-height: 300x; */
  height: auto;

.slick-dots {
    display: flex!important;
    align-items: center;
    justify-content: center;
    padding: 10px;
    bottom: 4px;
    background-color: transparent;

    li {
        margin: 0px 1px;
    }
    li button:before {
        font-size: 13px;
        color: #c78e35;
    }
    li.slick-active button:before {
        opacity: .75;
        color: #da911f;
    }
}
`
