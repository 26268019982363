// Library Imports
import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import styled, { withTheme } from 'styled-components';
import { theme } from "styled-tools";
import { useLazyQuery } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";
// Component Imports 
import Intl from "src/components/common/Intl";
import GlobalModal from "src/components/GlobalModal";
import { LoginInput } from "src/components/Auth/Login/Login";
import { Input } from "src/components/udb/commonStyle";
import { AuthButtonWrap, AuthSubmitBtn, BackButton, TextHeadVertical, Tittle } from "src/components/Auth/commonStyles";

// Global Imports 
import config from "src/config/config";
import { AES, pad, mode, enc } from "crypto-js";

// Images Components
import HorseImage from "src/images/login_horse.png";
// import Logo from "src/images/desktop_logo.gif";

// Util Imports
import util from "src/util/util";
import cookieUtil from "src/util/cookieUtil";
import { ECookieName } from "src/util/utilModel";

// Other Imports
import { GET_USERINFO } from "src/graphql/member";
import { withAppContext } from "src/store/initAppContext";
import ForgotPassword from "./Signup/forgotPassword";
import ForgetUsername from "./ForgotUserName/forgetUsername";
import SignUp from "./Signup/Signup";
import { login, resendOtp } from "src/config/api";
import EyeIcon from 'src/components/icon/Eye';
import HideIcon from 'src/components/icon/Hide';
import SEOContents from "src/components/SEOContents";
import {SRTM} from 'src/util/sportradarEvent';
import { Helmet } from "react-helmet";
import { CRMLoginSuccess, CRMPageVisit } from "src/util/crmUtil";
import FomoSponsorsSlider from "./FomoSponsorsSlider";


export type UserOptions = {
  phoneNumber: string,
};

const structuredData = (brandId) => {
  let domainURL = 'https://www.yolo247.site';

  if (brandId === 32) {
    domainURL = 'https://www.yolo247.club';
  }
  else if (brandId === 33) {
    domainURL = 'https://www.betdaily.com';
  }
  else if (brandId === 34) {
    domainURL = 'https://www.betdaily.club';
  }
  else if (brandId === 39) {
    domainURL = 'https://www.fomo7.com';
} else if (brandId === 40) {
  domainURL = 'https://www.fomo7.xyz';
}

  const data = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement":
      [
        {
          "@type": "ListItem",
          "item": `${domainURL}`,
          "name": "Homepage",
          "position": 1
        },
        {
          "@type": "ListItem",
          "item": `${domainURL}/login`,
          "name": "Login",
          "position": 2
        }
      ],
    "name": "Breadcrumbs"
  }

  return JSON.stringify(data)
}


function LoginPage(props) {
  const navigate = useNavigate()
  const {
    watch,
  } = useForm({
    mode: "onChange",
  });
  const TOKEN_SECRET_KEY = config.secretKey;
  // const watchMobileNumber = watch("MobileNumber", 0);
  const { app: { setState, handleLogin, handleSignup, showTimer, showResend, brandId },theme } = props
  const [page, setPage] = useState(0);
  const [isModalOpen, setModal] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [token, setToken] = useState(null);

  const [getUserInfo, { loading, error, data }] = useLazyQuery(GET_USERINFO, {
    fetchPolicy: "network-only",
    onError: (error) => {
      setErrorMessage(error.message)
    },
    onCompleted: (data) => {
      handleSuccess(data);
    },
  });
  const [otpLoader, setOtpLoader] = useState(false);
  const [otpHandle, setOtpHandle] = useState(false);
  const [networkMessage, setNetworkMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false)
  let userName = cookieUtil.get(ECookieName.COOKIE_LOGGEDIN_USERNAME)
  userName = cookieUtil.decryptKey(ECookieName.COOKIE_LOGGEDIN_USERNAME, userName);

  const [isAuthenticated, setAuthenticated] = useState(false)

  const [userData, setUserData] = useState({
    first_time_login: false,
    first_time_deposit:false,
    is_change_password: false,
    user_registered_at:''
  })
  const { config: { showsignup = true,showNotAble2Login = true, showFomoSponsorSlider } } = theme;

  // Check for modal Props here
  useEffect(() => {
    if (handleLogin) {
      triggerModal(true)
    }
    return () => {
      setState({ handleSignup: false });
      setState({ showTimer: false });
      setState({ showResend: false })
    }
  }, [handleLogin])

  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (cookieUtil.get(ECookieName.COOKIE_TOKEN)) {
      navigate('/')
    }

    //sport radar
    SRTM.init(brandId);
    //for CRM
    CRMPageVisit();
  }, [])

  const triggerModal = (value: boolean) => {
    setModal(value);
  };

  const handleCallback = (childData) => {
    setPage(childData);
    triggerModal(true)
  };

  const closeModal = () => {
    triggerModal(false);
    setPage(0);
  };

  const modalHandler = () => {
    if (typeof setState === 'function') {
      let _reqData = {
        handleSignup: true
      }
      setState(_reqData)
    }
    closeModal()
  }

  const sendOTP = async (event: React.MouseEvent<HTMLElement>, formData) => {
    // setTimeout(() => {
      if (typeof setState === 'function') {
        let _reqData = {
         
          showResend: false
        }
        setState(_reqData)
      }
    // }, 30000);
    event.preventDefault();
    event.stopPropagation();
    setOtpLoader(true)
    setOtpHandle(true);
    const formvalue = formData()
    const info = JSON.stringify({
      phoneNumber: formvalue.MobileNumber,
      phoneCountry: "in",
      "brandId":brandId
    });
    const encryptedTarget = enc.Utf8.parse(info);
    const encryptSecretKey = enc.Utf8.parse(TOKEN_SECRET_KEY);
    const authInfo = AES.encrypt(encryptedTarget, encryptSecretKey, {
      iv: enc.Utf8.parse(""),
      padding: pad.Pkcs7,
      mode: mode.CBC,
    }).ciphertext.toString();
    let data = {
      resetInfo: authInfo,
    };
    resendOtp(data)
      .then((response) => {
        setOtpLoader(false);
        setIsSuccess(true);
        if (typeof setState === 'function') {
          let _reqData = {
            showTimer: true,
            showResend: false
          }
          setState(_reqData)
        }
        setTimeout(() => {
          if (typeof setState === 'function') {
            let _reqData = {
              showTimer: false,
              showResend: true
            }
            setState(_reqData)
          }
        }, 75000);
      })
      .catch((error) => {
        let errorResponse = error.response?.data
        setOtpHandle(false);
        setNetworkMessage(errorResponse?.error);
        setOtpLoader(false)
      })
  };

  const handleSuccess = (data) => {
    const payload = util.getValue(data, ["member", "userInfo"], {});
    if (payload.length !== 0) {
      cookieUtil.set(ECookieName.COOKIE_USERNAME, payload.username, 30);
      cookieUtil.set(ECookieName.COOKIE_MEMBERID, payload.memberId, 30);
      cookieUtil.set(
        ECookieName.COOKIE_ORIGINAL_MEMBERID,
        payload.memberId,
        30
      );
      cookieUtil.set(
        ECookieName.COOKIE_ORDER_LIMIT,
        JSON.stringify(payload.orderLimit),
        30
      );
      cookieUtil.set(ECookieName.COOKIE_LANG, "en-us", 30);
      cookieUtil.set(
        ECookieName.COOKIE_EXCHANGE_RATE,
        payload.exchangeRate,
        30
      );
      cookieUtil.set(ECookieName.COOKIE_ACCOUNT_TYPE, "CLIENT");
      cookieUtil.set(
        ECookieName.COOKIE_IS_SUBACCOUNT,
        payload.isSubAccount ? "1" : ""
      );
      cookieUtil.set(
        ECookieName.COOKIE_TOKEN,
        token ? token : localStorage.getItem(ECookieName.COOKIE_TOKEN)
      );
      cookieUtil.set(
        ECookieName.COOKIE_ACCOCIATIONS,
        JSON.stringify(payload.associations || [])
      );
      cookieUtil.set(
        ECookieName.COOKIE_USERINFO,
        JSON.stringify(payload || {})
      );
      cookieUtil.set(
        ECookieName.COOKIE_BFPT_INFO,
        JSON.stringify(
          payload.betfairInfo.map(
            (_: { betfairPt: number; categoryId: number }) => ({
              bfPt: _.betfairPt,
              categoryId: _.categoryId,
            })
          )
        ),
        30
      );
      cookieUtil.set(
        ECookieName.COOKIE_BRAND_ID,
        payload.brandId ? payload.brandId : brandId
      );
      //setting for showing/hiding change pWD popup
      cookieUtil.set(
        ECookieName.COOKIE_IS_DEFAULT_PASSWORD,JSON.stringify(userData.is_change_password)
      );

      CRMLoginSuccess(brandId,payload.username, payload.memberId); //CRM Login Succes
  
      navigate('/', {state:{
        first:userData.first_time_login,
        fd:userData.first_time_deposit,
        fpwd: userData.is_change_password,
        iplPopup:userData.user_registered_at
      }});
      window.location.reload();

      //sportrader
      SRTM.loginEvent(brandId,payload.username)
    }
  };

  const onSubmit = ({ username, password, remember_me, ageValid }, event) => {
    if (remember_me) {
      cookieUtil.set(ECookieName.COOKIE_LOGGEDIN_USERNAME, cookieUtil.encryptKey(ECookieName.COOKIE_LOGGEDIN_USERNAME, `${username}/${password}`), 30);
    }
    event.preventDefault();
    event.stopPropagation();
    let userInfo = {
      username,
      password,
      brandId
    };

    //encrypting the login user info 
    const info = JSON.stringify(userInfo);
    const encryptedTarget = enc.Utf8.parse(info);
    const encryptSecretKey = enc.Utf8.parse(TOKEN_SECRET_KEY);
    const authInfo = AES.encrypt(encryptedTarget, encryptSecretKey, {
      iv: enc.Utf8.parse(""),
      padding: pad.Pkcs7,
      mode: mode.CBC,
    }).ciphertext.toString();

    let data = {
      loginInfo: authInfo,
    };

    login(data)
      .then((response: any) => {
        let res = response.data;
        setToken(res.token);
        
        setUserData({
          first_time_login: res.is_first_time_login,
          first_time_deposit:res.is_first_time_deposit,
          is_change_password: res.is_change_password,
          user_registered_at:res.user_registered_at
        })

        if (brandId == 32 || brandId==34 || brandId === 40) {
          sessionStorage.setItem('clubUserCreated', res.user_registered_at)
        }
        
        getUserInfo({
          variables: {
            input: {
              token: res.token,
            },
          },
        });

      })
      .catch((error) => {
        let errormessage = error.response.data
        setNetworkMessage(errormessage.error)
      })
  };

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    register,
  } = useForm({
    mode: "all",
    defaultValues: {
      username: userName ? userName.split('/')[0] : "",
      password: userName ? userName.split('/')[1] : "",
      remember_me: "",
      ageValid: true,
    },
  });

  if (loading) return '';
  if (error) {
    setErrorMessage(error.message);
  }

  const handleLoginButton = () => {
    setPage(1);
    if (typeof setState === 'function') {
      let _reqData = {
        showTimer: false,
        showResend: false
      }
      setState(_reqData)
    }
  }

  const handleBackButton = () => {
    setPage(page - 1);
    if (typeof setState === 'function') {
      let _reqData = {
        showTimer: false,
        showResend: false
      }
      setState(_reqData)
    }
  }

  const handleUsernameClick = () => {
    setPage(2);
    if (typeof setState === 'function') {
      let _reqData = {
        showTimer: false,
        showResend: false
      }
      setState(_reqData)
    }
  }

  const handlepasswordClick = () => {
    setPage(1);
    if (typeof setState === 'function') {
      let _reqData = {
        showTimer: false,
        showResend: false
      }
      setState(_reqData)
    }
  }

  const formatInput = (e) => {
    return /\s/g.test(e);
  }

  // const closeTootltip = () => {
  //   setTooltipState(false);
  // }
  return (
    <div>
      {/* <LoginButton>
        <button type="button">
          <Intl langKey="LOGIN@TITLE" />
        </button>
      </LoginButton> */}
      <Helmet>
        <script type="application/ld+json">
          {
            structuredData(brandId)
          }

        </script>
      </Helmet>
      <SEOContents pageName={"login"}/>
      <GlobalModal
        isModalOpen={isModalOpen}
        closeModal={(value) => navigate(-1)}
      >
        {errorMessage}
        <LoginContentWrapper className="loginModal">
          <LeftBlock>
            {page === 0 &&
              <LoginTitleWrap>
                <Tittle>
                  <Intl langKey="LOGIN@TITLE" />
                </Tittle>
              </LoginTitleWrap>}
            {page === 1 && (
              <TextHeadVertical>
                <Tittle>
                  <Intl langKey="LOGIN@PASSWORD@LABEL" />
                </Tittle>
              </TextHeadVertical>
            )}
            {page === 2 && (
              <TextHeadVertical>
                <Tittle>
                  <Intl langKey="LOGIN@USERNAME@LABEL" />
                </Tittle>
              </TextHeadVertical>
            )}
            <LoginTopWrap>
              <LoginTopLeftBlock>
                <Link className="loginSignup_logo" to="/">
                  {brandId == 33 || brandId == 34 ? <img src={`${config.imageV3Host}/static/cashsite/${theme.image.new_whitelable_logo_betdaily}`}  className='betdaily' alt="" /> : brandId == 39 || brandId == 40 ? <img src={`${config.imageV3Host}/static/cashsite/${theme.image.new_whitelable_logo_fomo}`}  className='betdaily' alt="" /> : <img src={`${config.imageV3Host}/static/cashsite/${theme.image.new_whitelable_logo}`} alt="Logo" />}
                </Link>

              </LoginTopLeftBlock>
              {page === 0 &&  (
                <LoginTopRightBlock>
                 {showsignup && <p>
                    {" "}
                    <Intl langKey="AUTH@CLICKHERETO" />
                    <button onClick={() => navigate('/signup')}><Intl langKey="SIGNUP@TITLE" /></button>{" "}
                  </p>}
                </LoginTopRightBlock>
              )}
              {page === 1 && (
                <LoginTopRightBlock>
                  <p>
                    {" "}
                    <Intl langKey="FORGOT@USERNAME" />
                    <button onClick={handleUsernameClick}><Intl langKey="AUTH@CLICKHERE" /></button>
                  </p>
                </LoginTopRightBlock>
              )}
              {page === 2 && (
                <LoginTopRightBlock>
                  <p>
                    {" "}
                    <Intl langKey="FORGOT@PASSWORD" />
                    <button onClick={handlepasswordClick}><Intl langKey="AUTH@CLICKHERE" /></button>
                  </p>
                </LoginTopRightBlock>
              )}
            </LoginTopWrap>

            <LoginDetailsWrap>

              {page === 0 && (
                <>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                      control={control}
                      name="username"
                      rules={{
                        required: {
                          value: true,
                          message: "Please enter username",
                        },
                        pattern: {
                          value: /^[a-zA-Z0-9]*$/,
                          message:
                            "* Name must not contain special characters and spaces",
                        },
                        minLength: {
                          value: 2,
                          message: "Minimum Character : 2",
                        },
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <>
                          <LoginInput
                            id="username"
                            placeholder={<Intl langKey="LOGIN@ID" />}
                            type="text"
                            onChange={onChange}
                            onKeyDown={formatInput}
                            value={value}
                            error={error}
                            req={true}
                            name={"username"}
                          />
                          <h1>{error && error.message}</h1>
                        </>
                      )}
                    />

                    <Controller
                      control={control}
                      name="password"
                      rules={{
                        required: {
                          value: true,
                          message: "Please enter password",
                        },
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <SignUpPasswrdWrap>
                          <LoginInput
                            id="password"
                            placeholder={<Intl langKey="LOGIN@PASSWORD" />}
                            onChange={onChange}
                            value={value}
                            error={error}
                            req={true}
                            name={"password"}
                            type={values.showPassword ? "text" : "password"}
                          />
                          <PasswordHide onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >{values.showPassword ? <HideIcon /> : <EyeIcon />}
                          </PasswordHide>
                          <h1>{error && error.message}</h1>
                        </SignUpPasswrdWrap>
                      )}
                    />

                    <LoginBottomWrap>
                      <Controller
                        control={control}
                        name="remember_me"
                        rules={{
                          required: false,
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <RadioBtnWrapper>
                            <BtnText>
                              <Input
                                type="checkbox"
                                name="default"
                                value={value}
                                onClick={onChange}
                              />
                              <Intl langKey="LOGIN@REMEMBER_ACCOUNT" />
                            </BtnText>
                          </RadioBtnWrapper>
                        )}
                      />

                      <Controller
                        control={control}
                        name="ageValid"
                        rules={{
                          required: {
                            value: true,
                            message: "Please Confirm You Are Above 18 Years Old",
                          },
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <>
                            <RadioBtnWrapper>
                              <BtnText>
                                <Input
                                  {...register("ageValid")}
                                  type="checkbox"
                                  name="ageValid"
                                  value={props.value}
                                  checked={props.value}
                                  onClick={onChange}
                                />
                                <div className="agreeAge">
                                  <Intl langKey="LOGIN@CHECKBOX" />
                                  <span className="agreeAgeColor"><Intl langKey="LOGIN@CHECKBOX2" /></span>&
                                  <span className="agreeAgeColor"><Intl langKey="LOGIN@CHECKBOX3" /></span>
                                </div>
                              </BtnText>
                            </RadioBtnWrapper>
                            <p>{error && error.message}</p>
                          </>
                        )}
                      />
                    </LoginBottomWrap>

                    <ErrorWrap>
                      {networkMessage && networkMessage.length !== 0 && <p className="username-error1">{networkMessage}</p>}
                    </ErrorWrap>

                    <AuthButtonWrap>
                      <AuthSubmitBtn disabled={!isValid} type="submit">
                        <Intl langKey="LOGIN@TITLE" />
                      </AuthSubmitBtn>
                    </AuthButtonWrap>

                  </form>

                  {showNotAble2Login && <LoginFooterMessage>
                    <p>
                      <Intl langKey="LOGIN@NOTABLE" />
                      <button onClick={handleLoginButton}> <Intl langKey="AUTH@CLICKHERE" /></button>
                    </p>
                  </LoginFooterMessage>}

                </>
              )}
              {page === 1 && <ForgotPassword isSuccess={isSuccess} onSendOtpHandler={sendOTP} otpHandle={otpHandle} setOtpLoader={otpLoader} loading={loading} showResend={showResend} showTimer={showTimer} parentCallback={handleCallback} />}
              {page === 2 && <ForgetUsername isSuccess={isSuccess} parentCallback={handleCallback} onSendOtpHandler={sendOTP} otpHandle={otpHandle} setOtpLoader={otpLoader} loading={loading} />}
              {page === 3 && <SignUp parentCallback={handleCallback} />}

            </LoginDetailsWrap>
            <BackButton>
              <button
                className="backbutton"
                onClick={page === 1 || page === 2 ? handleBackButton : null}
                style={page === 1 || page === 2 ? { display: "block" } : { display: "none" }}
              >
                {page === 1 || page === 2 ? <Intl langKey="AUTH@BACK" /> : null}
              </button>
            </BackButton>
          </LeftBlock>
          {showFomoSponsorSlider && <RightBlockWrapper>
            <FomoSponsorsSlider />
          </RightBlockWrapper>}

          <button
            className="close"
            type="button"
            onClick={() => navigate('/')}
          ></button>

        </LoginContentWrapper>
      </GlobalModal>
    </div>
  );
}

export default withTheme(withAppContext(LoginPage));

const SignUpPasswrdWrap = styled.div`
    position: relative;
`;

const LoginButton = styled.div`
transform: skew(340deg);
// border: 1px solid #CB9640;
margin-left: 20px;
// background: ${theme('palette.primary-background-color-linear-new')};
// box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.25);
background: #6778E3;
border-radius: 5px;
  
    button {
        width: 80px;
        text-align: center;
        display: block;
        text-decoration: none;
        padding: 10px 0px;
        font-size: 14px;
        transform: skew(-340deg);
        color: ${theme('palette.logintextcolor-new')};
        
        text-transform: uppercase;
        background-color: transparent;
        border: none;
        cursor: pointer;
    }
`;

export const LoginTopLeftBlock = styled.div`
  width: 50%;

  & img {
    width: 120px;
    padding: 5px 15px;
  }
  .betdaily{
    width: 150px;
    padding: 5px 5px;
  }
  
`;

export const LoginTopWrap = styled.div`
  /* background:#323232; */
  background: ${theme('palette.loginTopHeadWrapperBg')};
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 10px;  
  border-radius: 10px 10px 0px 0px;
`;

const LoginBottomWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 5px 20px 1px 20px;
  flex-wrap: wrap;

  & p {
    font-size: 12px;
    color: red;
    margin: 4px 0px;
  }
`;

export const LoginTitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
 background: ${theme('palette.secondary-background-color-linear-new')};
  padding: 5px 15px;
  position: absolute;
   left: -36px;
  -ms-transform: rotate(20deg);
  transform: rotate(270deg);
  height: 25px;
`;

const LoginTitle = styled.h3`
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
  position: relative;
  top: -12px;
  color: #fff;
`;

export const LoginTopRightBlock = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  & span {
    text-decoration: underline;
    color: #ffd500;
    /* font-family: "Roboto"; */
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
  }

  & p {
    color: white;
    /* font-family: "Roboto"; */
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    padding-right: 5px;
    text-transform: capitalize;
    margin: 0px;
  }

  button {
    font-weight: 500;
    font-size: 14px;
    text-decoration: underline;
    font-style: italic;
    cursor: pointer;
    background: transparent;
    border: none;
    /* color: orange; */
    color: ${theme('palette.AuthSignupTextColor')};
    text-decoration: underline;
  }
`;

export const LoginDetailsWrap = styled.div`
  margin: auto;
  position: relative;
  width: 85%;
  padding: 30px 15px 30px 20px;

  & h1 {
    font-size: 13px;
    color: red;
    margin: 3px 0px;
  }
`;

const RadioBtnWrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  padding: 0px;

  ${Input} {
    width: 18px !important;
    height: 18px !important;
    border: 1px solid #4643c2;
    margin: 0;
    margin-right: 5px;
  }
  & input[type=checkbox] {
        accent-color: ${theme('palette.AuthCheckboxColor')};
    }
`;

export const LoginContentWrapper = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.AuthWrapBgNewIPL')});
  background-size: cover;
  /* background-position: right; */
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  z-index: 99999;

  .login_logo {
    width: 290px;
    height: 190px;
    display: flex;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    right: -8px;
    top: 0px;
  }

  .horse_bg {
    width: 550px;
    height: 475px;
    display: flex;
    background-image: url(${config.imageS3Host}${HorseImage});
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
`;

export const LeftBlock = styled.div`
  /* width: 550px;
  height: auto;
  max-width: 650px;
  min-height: 350px;
  max-height: 64vh; */
  width: 38%;
  height: 45%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  backdrop-filter: blur(40.349998474121094px);
  background: ${theme('palette.AuthLoginBackground')};
  /* background: linear-gradient(0deg, #000000, #000000), radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.25) 100%); */
  border-radius: 10px;
  /* margin: 40px 0px 0px 40px; */
  margin: 5% 0% 1% 7%;
  position: relative;

  /* @media (max-width: 1350px) , screen and (min-height: 700px){
    width: 550px;
    max-width: 650px;
    // max-height: 450px;
  }

  @media (max-width: 1220px) {
    width: 550px;
    max-width: 650px;
  } */
`;

const BtnText = styled.label`
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
  font-size: 11px;
  margin-left: 5px;

  .agreeAgeColor {
    color: #F6A72D;
    text-decoration-line: underline;
  }
`;

const LoginFooterMessage = styled(LoginTopRightBlock)`
  width: 100%;
  margin-top: 15px;

  & p,
  span {
    font-style: normal;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 13px;
    font-weight: 400;
  }

  & a {
    text-decoration: underline;
    display: flex;
    align-items: center;

    &:hover {
      text-decoration: underline;
      color: #ffd500 !important;
    }

    & p {
      text-decoration: none;
    }
  }
`

const ErrorWrap = styled.div`
& p {
  font-size: 15px;
  text-align: center;
  color: white;
  font-weight: 500;
  background-color: #c91414;
  padding: 5px;
  border-radius: 30px;
}
`;

const PasswordHide = styled.div`
    display: block;
    width: 5px;
    position: absolute;
    right: 25px;
    top: 15px;
`;

const RightBlockWrapper = styled.div`
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;