import React, { useEffect, useState } from "react";
import styled, { withTheme } from "styled-components";
import { HeadingTextWrapper } from "../../common/Headings";
// import CasinoSlider from './casino-slider';
// import RecommendedGames from './recommended-games';
// import CasinoTabGames from './casino-tab-games';
// import { bgcolor, color } from "../../../sass-files/variable";
// import BingoMachine from '../../../images/casino-images/bingo_machine_laptop.jpg';
// import goldenegglaptop from '../../../images/casino-images/golden_egg_laptop.jpg'
import { withAppContext } from "src/store/initAppContext";
// import Loading from '../../../../src/pages/loading'
// import LeftArrow from "../../../images/arrow-left_gold.png"
import { useMutation } from "@apollo/client";
import teenpattidata from "src/static/game-data/teenpatti.json"
import { ONE_CLICK_RECYCLE } from "src/graphql/system";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PlayDemoGames from "./bingo-slotGames";
// import teenpattiPlus1 from 'src/images/casino-images/tennpatti2020.jpg';
// import andaarbahar from 'src/images/casino-images/andar_bahar11.jpg';
import {
  agIcon,
  allIcon,
  ezugiIcon,
  supernowaIcon,
  tvBetIcon,
} from "./casino-tab-games";
import Intl from "../../common/Intl";
import Tabs from "./casino-tab";
import { motion } from "framer-motion";
import config from "src/config/config";
import cookieUtil from "src/util/cookieUtil";
import { ECookieName } from "src/util/utilModel";
// import { theme } from 'styled-tools';
import SEOContents from "src/components/SEOContents";
import TeenpattiSEO from "src/components/SEOComponents/TeenpattiSEO";
import Teenpatti2020 from "src/components/SEOComponents/teenpatti2020";
import { Helmet } from "react-helmet";

//AG GAMES
// import teenpattiPlus1 from 'src/images/casino-images/teenpattinew.jpg';
// import andaarbahar from 'src/images/casino-images/teenpattiandarbahar.jpg';
// import roulette2 from 'src/images/casino-images/teenpattiroulette2.jpg';
// import baccarat2 from 'src/images/casino-images/teenpattibaccarat2.jpg';
// import baccarat3 from 'src/images/casino-images/teenpattibaccarat3.jpg';
// import baccarat4 from 'src/images/casino-images/teenpattibaccarat4.jpg';

// import blackjack1 from 'src/images/casino-images/teenpattiblackjack1.jpg';
// import blackjack2 from 'src/images/casino-images/teenpattiblackjack2.jpg';
// import dragontiger from 'src/images/casino-images/teenpattidragontiger.jpg';
// import teenpattibaccarat from 'src/images/casino-images/teenpattibaccarat.jpg';
// import teenpattiRoulette from 'src/images/casino-images/teenpattiroulette.jpg';

// AG HIndi games
// import teenpattiPlus1Hindi from 'src/images/casino-images/teenpatti2020Hindi.jpg';
// import andaarbaharHindi from 'src/images/casino-images/andarbaharHindi.jpg';
// import baccarat2Hindi from 'src/images/casino-images/baccaratHindi.jpg';
// import baccarat3Hindi from 'src/images/casino-images/baccarat3Hindi.jpg';
// import blackjack1Hindi from 'src/images/casino-images/blackJackHindi.jpg';
// import blackjack2Hindi from 'src/images/casino-images/blackJack2hindi.jpg';
// import dragontigerHindi from 'src/images/casino-images/dragontigerHindi.jpg';
// import teenpattibaccaratHindi from 'src/images/casino-images/bacarratHindi.jpg';
// import teenpattiRouletteHindi from 'src/images/casino-images/rouletteHindi.jpg';

// AG games filter icons
// import allIcon from '../../../images/AllProviderIcon.png';
// import tvBetIcon from '../../../images/tvbet.png';
// import ezugiIcon from '../../../images/Ezugi.png';
// import supernowaProvider from 'src/images/casino-images/supernowa.png';
// import agIcon from 'src/images/casino-images/AG.png';

// AG English images
// import agteenpatti2020 from 'src/images/casino-images/Teenpatti2020_ag.jpg';
// import agteenpattiplus from 'src/images/casino-images/Teenpattiplus_ag.jpg';
// import ezugiBetonTeenpatti from 'src/images/casino-images/Betonteenpatti_ezugi.jpg';
// import Ezugi3cardTeenpatti from 'src/images/casino-images/teenpatti3card_ezugi.jpg';
// import tvbetTeenpatti from 'src/images/casino-images/Teenpati_tvbet.jpg';
// import teenpattisupernowa from 'src/images/casino-images/teenpati_supernova.jpg';

// AG HIndi images
// import agteenpatti2020hindi from 'src/images/casino-images/Teenpatti-2020_ag.jpg';
// import agteenpattiplushindi from 'src/images/casino-images/Teenpatti-Plus_Ag.jpg';
// import ezugiBetonTeenpattihindi from 'src/images/casino-images/Beton-teenpatti_ezugi.jpg';
// import Ezugi3cardTeenpattihindi from 'src/images/casino-images/Teenpatti-3-card_ezugi.jpg';
// import tvbetTeenpattihindi from 'src/images/casino-images/Teenpatti_tvbet.jpg';
// import teenpattisupernowahindi from 'src/images/casino-images/Teenpatti_supernowa.jpg';
// const supernowaProvider = `${'/static/media/supernowa.png'}`;
// const agIcon = `${'/static/media/ag.png'}`

// All English and Hindi images
// const agteenpatti2020 = `${"/static/common/media/AGTeenPattiPlus1.png"}`;
// const agteenpatti2020hindi = `${"/static/common/media/AGTeenPattiPlus1Hindi.png"}`;
// const agteenpattiplus = `${"/static/common/media/AgTeenpattiPlus.png"}`;
// const agteenpattiplushindi = `${"/static/common/media/AgTeenpattiPlusHi.png"}`;
// const ezugiBetonTeenpatti = `${"/static/common/media/BetonTeenpattiEzugi.png"}`;
// const ezugiBetonTeenpattihindi = `${"/static/common/media/BetonTeenpattiEzugiHi.png"}`;
// const Ezugi3cardTeenpatti = `${"/static/common/media/Ezugi3cardTeenpatti.png"}`;
// const Ezugi3cardTeenpattihindi = `${"/static/common/media/Ezugi3cardTeenpattiHi.png"}`;
// const tvbetTeenpatti = `${"/static/common/media/TvbetTeenpatti.png"}`;
// const tvbetTeenpattihindi = `${"/static/common/media/TvbetTeenpattiHi.png"}`;
// const teenpattisupernowa = `${"/static/common/media/TeenpattiSupernowa.png"}`;
// const teenpattisupernowahindi = `${"/static/common/media/TeenpattiSupernowaHi.png"}`;
// const TeenPattiExpress = `${'/static/common/media/TeenPattiExpressMplayNew.png'}`
// const TeenPattiExpressHi = `${'/static/common/media/TeenPattiExpressMplayNewHi.png'}`
// const TeenPattiChampion = `${'/static/common/media/TeenPattiChampionMplayNew.png'}`
// const TeenPattiChampionHi = `${'/static/common/media/TeenPattiChampionMplayNewHi.png'}`

const mplayicon = `${'/static/media/mplayicon.png'}`;

let ALLGAMES = <Intl langKey="GAME@ALL" />;
let AG = <Intl langKey="GAME@AG" />;
let SUPERSPADE = <Intl langKey="GAME@SUPERSPADE" />;
let EZUGI = <Intl langKey="GAME@EZUGI" />;
let TVBET = <Intl langKey="GAME@TVBET" />;
let SUPERNOWA = <Intl langKey="TITLE@SUPERNOWA" />;
let MPLAY = <Intl langKey="GAME@MPLAY" />;
const Language = cookieUtil.get(ECookieName.COOKIE_LANG);
const providerTab = [ALLGAMES, AG, EZUGI, TVBET, SUPERNOWA, MPLAY];

const providerIcons = [
  {
    id: 1,
    name: ALLGAMES,
    icon: allIcon,
  },

  {
    id: 12,
    name: AG,
    icon: agIcon,
  },
  {
    id: 12,
    name: EZUGI,
    icon: ezugiIcon,
  },
  {
    id: 12,
    name: TVBET,
    icon: tvBetIcon,
  },
  {
    id: 12,
    name: SUPERNOWA,
    icon: supernowaIcon,
  },
  {
    id: 13,
    name: MPLAY,
    icon: mplayicon
  },
];

// const {titleHeadColor} = color
// const { secondarycolor, menucolor } = color;
// const {loginbghover} = bgcolor
// const {signupbghover} = bgcolor

const structuredData = (domain) => {
  const data = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement":
          [
              {
                  "@type": "ListItem",
                  "item": `${domain}`,
                  "name": "Homepage",
                  "position": 1
              },
              {
                  "@type": "ListItem",
                  "item": `${domain}/teenpatti/`,
                  "name": "Play Teentti Online",
                  "position": 2
              },
              {
                  "@type": "ListItem",
                  "item": `${domain}/teenpatti/teenpatti2020`,
                  "name": "Play 3patti 2020",
                  "position": 3
              }],
      "name": "Breadcrumbs"
  }

  return JSON.stringify(data)
}




function Teenpatti(props:any) {

  const { app: { brandId }, theme } = props
  const { config: {
    showSeoFooterInsidePages,
    showSEOfooterForYolo
  } } = theme
  const location = useLocation();
  const getLastqueryString = location.pathname.split("/").pop();

  const { id } = useParams()
  
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  // const [isGameTrigger, setGameTrigger] = useState(false);
  // const [gameDetails, setGameDetails] = useState({});
  // const [reload, setReload] = useState(false);
  const [triggerOneClickRecycle]: any = useMutation(ONE_CLICK_RECYCLE);
  // const [filterBingoImages, setFilterBingoImages] = useState([]);
  const [casinoImages, setCasinoImages] = useState(teenpattidata);

  const [filteredData, setfilteredData] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
  // Now Lazy load game data 
    import('src/static/game-data/teenpatti.json').then(data => data.default).then(data => {
      setCasinoImages(data);

      if (id) {
        searchData(id);
    } else {
      setfilteredData(data);
    }

      
  }).catch(err => console.log('Something went wrong. Unable to fetch game data', err))
  
  
  }, []);

  let history: any = useNavigate();

  const backButton = () => {
    window.location.reload();
    triggerOneClickRecycle()
    .then((response) => {
        if (response) {
            return;
        }
    })
    .catch((error) => {
        console.log("Error in one click recycle");
    });
  };

  const searchData=(val:any)=>{
    setSearch(val)
    if(val !== ""){
        const filteredItems= casinoImages.filter((item)=>{
            const { imgsrc, ...rest } = item;
            return  Object.values(rest).join('').toLowerCase().includes(val.toLowerCase())
          })
          setfilteredData(filteredItems)
    }
    else{
        setfilteredData(casinoImages)
    }
}

  const budgetData = () => { };

  let domainURL = 'https://www.yolo247.site';

  if (brandId === 32) {
      domainURL = 'https://www.yolo247.club';
  }
  else if (brandId === 33) {
      domainURL = 'https://www.baaziadda.com';
  }
  else if (brandId === 34) {
      domainURL = 'https://www.betdaily.club';
  }

  return (
    <>
      <SEOContents pageName={"teenpattiOnlinePlay"} />
      {
        id ? (<Helmet>
        <script type="application/ld+json">
            {
                structuredData(domainURL)
            }

        </script>
    </Helmet>) : null
      }
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <CasinoWrapper theme={props.app.theme} customStyle={props.theme.image}>
          <TitleHead theme={props.app.theme} customStyle={props.theme.palette}>
            <Intl langKey="TITLE@TEENPATTI" />
          </TitleHead>
          {/* <BackButton onClick={backButton} disabled={true}> <img src={LeftArrow}/>
            Backertyuioiuytrertyuiuqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq
            </BackButton> */}

          <TabListSection>
            <Tabs
              searchText={search}
              providerIcons={providerIcons}
              tabs={providerTab}
              onSearch={searchData}
              isTabsHidden={false}
              budgetClick={budgetData}
              minWidthInContent={"initial"}
              render={(index) => {
                return (
                  <>
                    {providerTab[index] === ALLGAMES && (
                      <PlayDemoGames data={filteredData} />
                    )}
                    {providerTab[index] === AG && (
                      <PlayDemoGames
                        data={filteredData.filter((element, index) => {
                          return element.type === "AG";
                        })}
                      />
                    )}
                    {providerTab[index] === EZUGI && (
                      <PlayDemoGames
                        data={filteredData.filter((element, index) => {
                          return element.type === "EZUGI";
                        })}
                      />
                    )}
                    {providerTab[index] === TVBET && (
                      <PlayDemoGames
                        data={filteredData.filter((element, index) => {
                          return element.type === "TVBET";
                        })}
                      />
                    )}
                    {providerTab[index] === SUPERSPADE && (
                      <PlayDemoGames
                        data={filteredData.filter((element, index) => {
                          return element.type === "SUPERSPADE";
                        })}
                      />
                    )}
                    {providerTab[index] === SUPERNOWA && (
                      <PlayDemoGames
                        data={filteredData.filter((element, index) => {
                          return element.type === "SUPERNOWA";
                        })}
                      />
                    )}
                    {providerTab[index] === MPLAY && <PlayDemoGames data={filteredData.filter((element, index) => {
                      return element.type === 'MPLAY';
                    })} />}
                  </>
                );
              }}
            />
          </TabListSection>
          {showSeoFooterInsidePages && getLastqueryString === "teenpatti-online-play" && showSEOfooterForYolo? <TeenpattiSEO /> : showSEOfooterForYolo &&getLastqueryString=='TEENPATTI2020' ? <Teenpatti2020/>:<></>}
          

        </CasinoWrapper>
      </motion.div>
    </>
  );
}

export default withTheme(withAppContext(Teenpatti));

const TabListSection = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
`;

const CasinoWrapper: any = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  /* margin-top: -5px; */
  // height: 87vh;
  /* background-image: url('${(props: any) =>
    props.theme === "Dark"
      ? `${config.imageS3Host}/static/snk/${props.customStyle["new_whitelable_dark_background"]}`
      : `${config.imageS3Host}/static/snk/${props.customStyle["new_whitelable_light_background"]}`}'); */
  background-image: url("${(props: any) => `${config.imageV3Host}/static/cashsite/${props.customStyle["homeExchangeBgImage"]}`}");
  background-position: 100%;
  background-size: cover;
`;

const TitleHead: any = styled(HeadingTextWrapper)`
  font-size: 28px;
  letter-spacing: 3px;
  text-transform: uppercase;
  white-space: nowrap;
  font-weight: bold;
  /* color: ${(props: any) =>
    props.theme === "Dark"
      ? "#FFF"
      : props.customStyle["secondary-background-color-new"]}; */
  color: ${(props: any) => (props.theme === "Dark" ? "#FFF" : "#F4A322")};
  margin-left: 1em;
  padding-top: 10px;
  position: relative;
  margin-bottom: 10px;
  padding-bottom: 5px;

  :after {
    content: "";
    width: 500px;
    height: 2px;
    background: linear-gradient(90deg, #f7b349 0%, rgba(235, 160, 42, 0) 50%);
    position: absolute;
    bottom: 0;
    left: 0;
  }

  @media only screen and (max-width: 992px) {
    font-size: 30px;
  }
`;

// const SectionWrapper = styled.div`
//     width: 100%;
//     display: flex;
//     align-items: flex-start;
//     justify-content: space-between;
//     flex-direction: row;

//     img {
//         height:100%;
//         width: 100%;

//     }

//     .overlay {
//         position: absolute;
//         bottom: 0;
//         left: 0;
//         right: 0;
//         background-color: #0a285edb;
//         overflow: hidden;
//         width: 100%;
//         height: 100%;
//         -webkit-transform: scale(0);
//         -ms-transform: scale(0);
//         transform: scale(0);
//         -webkit-transition: .5s ease;
//         transition: .5s ease;
//       }

//       .buttons {
//         color: #fff;
//         font-size: 20px;
//         position: absolute;
//         top: 50%;
//         left: 50%;
//         -webkit-transform: translate(-50%, -50%);
//         -ms-transform: translate(-50%, -50%);
//         transform: translate(-50%, -50%);
//         text-align: center;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//       }
// `;

// const PlayButton = styled.div`
//     transform: skew(340deg);
//     border: 1px solid #CB9640;

//     box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.25);

//     :hover {
//         background: ${loginbghover};
//         transition: color 0.1s linear 0s;
//     }

//     button {
//         width: 80px;
//         text-align: center;
//         display: block;
//         text-decoration: none;
//         padding: 10px 0px;
//         font-size: 14px;
//         transform: skew(-340deg);
//         color: ${secondarycolor};

//         text-transform: uppercase;
//         background-color: transparent;
//         border: none;
//         cursor: pointer;
//     }
// `;

// const DemoButton = styled.div`
//     transform: skew(340deg);
//     border: 1px solid #CB9640;
//     margin-left: 10px;
//     background: linear gradient(#37B8BB(60% opacity),#1C5C5E(40% opacity));
//     box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.25);
//     transition: color 0.1s linear 0s;

//     :hover {
//         background: ${signupbghover};
//         transition: color 0.1s linear 0s;
//     }

//     button {
//         width: 80px;
//         text-align: center;
//         display: block;
//         text-decoration: none;
//         padding: 10px 0px;
//         font-size: 14px;
//         transform: skew(-340deg);
//         color: ${secondarycolor};

//         text-transform: uppercase;
//         background-color: transparent;
//         border: none;
//         cursor: pointer;
//     }
// `;

export const RowFirst = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .blocks {
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 4px 4px 4px rgba(0, 0, 0, 0.25); */
    padding: 2px;
    border-radius: 5px;
    width: 25%;
    box-sizing: border-box;
    flex: 0 0 24%; /* explanation below */
    margin: 0px 5px;

    // height: 100px;
  }

  .blocks_fullwidth {
    width: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
      inset 4px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 2px;
    margin: 3px;
    border-radius: 5px;
  }

  .top_up {
    position: relative;
    top: -25px;
  }
`;

// const FirstColoumn = styled.div`
//     width: 100%;
//     display: flex;
//     align-items: center;
//     flex-direction: column;

//     span {
//         width: 100%;
//         display: flex;
//         align-items: center;
//         flex-direction: row;
//         background-size: cover;
//         background-repeat: no-repeat;
//         position: relative;
//         margin: 5px 5px 0px 0px;
//         position: relative;
//         cursor: default;
//     }

//     span:hover .overlay {
//         -webkit-transform: scale(1);
//         -ms-transform: scale(1);
//         transform: scale(1);
//     }

//     .coltenth {
//         height: 230px;

//         align-items: flex-end;

//         h4 {
//             bottom: 30px;
//         }
//     }

//     h4 {
//         position: absolute;
//         left: 1.5em;
//         margin: 0;
//         font-size: 30px;
//         color: ${secondarycolor};

//         text-transform: uppercase;
//     }
// `;

// const BackButton= styled.button`
//     display: flex;
//     font-size: 15px;
//     color: #D0B99F;
//     -webkit-box-align: center;
//     align-items: center;
//     -webkit-box-pack: start;
//     justify-content: flex-start;
//     padding: -0.1em 1.7em;
//     background: ${theme('palette.backButtonColor')}
//     border-radius: 3px;
//     margin-left: 150px;
//     position: absolute;
//     top: 125px;
//     right: 0px;
//     cursor:pointer;
//     border: 0.25px solid #D0B99F;
//     // box-shadow: 0 0 3px #786B57;

// img{
//     padding-right:5px;
//     color:#fff;

// }

// :disabled{
//     color: red;
//     background-color: red;
//     display: block;
// }

// `;
